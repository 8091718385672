<template>
  <div class="select-languages" tabindex="1" @blur="visibleOptions = false">
    <div v-if="label" class="select-languages__label">{{ label }}</div>
    <div @click="openOptions" :class="['select-languages__inner', { active: visibleOptions }]">
      <div class="select-languages__inner-text">
        {{ $t(value?.name) }}
      </div>
      <img src="@/assets/svg/arrows/select-black.svg" alt="" />
    </div>
    <transition name="slide-bottom">
      <div v-if="visibleOptions" :class="['select-languages__options', {top: isTop}]">
        <div
            v-for="(item,key) in options"
            :key="key"
            @click="changeValue(item)"
            :class="['select-languages__options-item', { active: value?.name === item.name }]"
        >{{ $t(item.name) }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "UiSelectLanguages",

  props: {
    value: {
      type: [String, Object],
      default: () => {}
    } ,
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    isTop: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return{
      visibleOptions: false
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val)
      this.visibleOptions = false
    },

    openOptions() {
      if(!this.disabled) {
        this.visibleOptions = !this.visibleOptions
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.select-languages {
  position: relative;
  outline: none;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 10px;

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 11px;
    color: #9A9A9A;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 11px;
    }
  }

  &__inner {
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    font-weight: 400;
    font-size: 16px;
    line-height: 11px;
    color: #343432;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 11px;
    }

    img {
      transition: 0.3s;
    }

    &.active {

      img {
        transform: rotate(180deg);
      }
    }

    &-arrow {
      filter: opacity(0.5);
      width: 20px;
    }
  }

  &__options {
    position: absolute;
    top: 31px;
    max-height: 198px;
    max-width: 134px;
    width: 100%;
    left: 50px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: 0.3s;
    padding: 12px 12px 20px;
    overflow-y: auto;
    z-index: 100000;

    @media (max-width: 768px) {
      left: 33px;
    }

    &.top {
      top: -70px;
    }

    &-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #9A9A9A;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 11px;
      }

      &.active {
        color: #343432;
      }

      &:hover {
        color: #343432;
      }
    }
  }
}
</style>